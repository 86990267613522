import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {objectLang, removeCookieOfFolderId, setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "./ModalWin"
import {LoaderClass} from "./LoaderClass"

export const SaveNote = ({note}) => {

    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const {request, loading} = useHttp()

    let lang = objectLang().language

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/save/${note["_id"]}`,
                'POST',
                {
                    name: note.name,
                    body: note.body,
                    parentFolder: note.parentFolder
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(note.parentFolder)
                window.location.replace(`/folder/open/${note.parentFolder}`)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, request, note])

    useEffect(() => {
        if (!auth.isAuthenticated) {
            setAllowed(false)
            setDataModal(setErrorWindow(
                lang.body.errorPage.title,
                540,
                '',
                lang.body.errorPage.btnOK,
                'replaceToAuth',
                lang.body.errorPage.btnCancel,
                'replaceToBack'
            ))
        } else {
            getData().then()
        }
    }, [getData, auth, lang])

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }
}
