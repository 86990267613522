import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {setDataModalFromCatch, setErrorWindow} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {MainMoneyClass} from "../components/MainMoneyClass"
import {createBrowserHistory} from "history";

export const MainMoneyPage = () => {
    localStorage.setItem('page', 'MainMoneyPage')
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [dataMain, setDataMain] = useState({})
    const [currency, setCurrency] = useState('')
    const [allowed, setAllowed] = useState(true)
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataModalCatch, setDataModalCatch] = useState({})

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/main`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status !== 200) {
                if (data.status === 404) {
                    setAllowed(false)
                    setDataModal(setErrorWindow(
                        '',
                        data.code, data.message,
                        '',
                        'replaceTo /money/year/create',
                        '',
                        '',
                        'success',
                        ''
                    ))
                } else {
                    setAllowed(false)
                    setDataModal(setErrorWindow('', data.code, data.message, '', 'replaceTo /main'))
                }
            } else {
                setDataMain(data['years'])
                setCurrency(data['currency'])
                localStorage.setItem('pathBack', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [request, auth])

    useEffect(() => {
        getData().then()
    }, [getData])

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            <MainMoneyClass
                dataYears={dataMain}
                currency={currency}
            />
        </>
    )
}