import React, {useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {
    getDomain,
    getLink,
    objectLang,
    removeCookieOfFolderId,
    setDataModalFromCatch,
    setErrorWindow
} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {PathLineClass} from "../elements/PathLineClass"

export const CreateLinkPage = () => {
    localStorage.setItem('page', 'CreateLinkPage')
    const $ = require("jquery")

    let lang = objectLang().language
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const urlParams = new URLSearchParams(window.location.search)
    const folderId = urlParams.get('folderId')
    const [clipboard, setClipboard] = useState('')
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})

    useEffect(() => {
        window.M.updateTextFields()
    }, [])

    const clickHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        if (!$('#name').val() || !$('#link').val()) {
            setAllowed(false)
            setDataModal(setErrorWindow('', 0, lang.messages.fillToForm))
            return
        }
        const date = Date.now()
        const dateCreated = new Date(date)
        const timeZoneOffset = dateCreated.getTimezoneOffset()
        try {
            const data = await request(
                `${host}/api/link/create`,
                'POST',
                {
                    date: dateCreated,
                    offset: timeZoneOffset,
                    userId: auth.userId,
                    parentFID: folderId,
                    from: document.querySelector('#link').value,
                    name: document.querySelector('#name').value,
                    description: document.querySelector('#desc').value,
                    login: '',
                    password: ''
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                removeCookieOfFolderId(folderId)
                window.location.replace(`/folder/open/${data.link.parentFolder}`)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    navigator.clipboard.readText()
        .then((text) => {
            setClipboard(text)
        })

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    return (
        <>
            <PathLineClass documents={JSON.parse(localStorage.getItem('documents'))}/>
            <div className={"container d-flex flex-wrap justify-content-center"}>
                <div className={"col-12 col-md-9 mb-3 mt-3 d-flex"}>
                    <input
                        placeholder={lang.body.createLinkPage.placeholderName}
                        type={"text"}
                        className={"form-control"}
                        id={"name"}
                    />
                    <div id={"div-paste-name"}
                         className={"btn-paste btn btn-secondary bg-white"}>
                        <i id={'btn-paste-name'} className={`fa-regular fa-paste text-success`}
                           onClick={() => {
                               document.querySelector('#name').value = getDomain(clipboard)
                           }}
                        />
                    </div>
                </div>
                <div className={"col-12 col-md-9 mb-3"}>
                    <textarea
                        placeholder={lang.body.createLinkPage.placeholderDesc}
                        className={"form-control"}
                        id={"desc"}
                        rows={"2"}
                    />
                </div>
                <div className={"col-12 col-md-9 mb-3 d-flex"}>
                    <input
                        placeholder={lang.body.createLinkPage.placeholderLink}
                        type={"text"}
                        className={"form-control"}
                        id={"link"}
                    />
                    <div id={"div-paste-link"}
                         className={"btn-paste btn btn-secondary bg-white"}>
                        <i id={'btn-paste-link'} className={`fa-regular fa-paste text-success`}
                           onClick={() => {
                               document.querySelector('#link').value = getLink(clipboard)
                           }}
                        />
                    </div>
                </div>

                <button
                    className={"col-4 col-md-3 btn btn-info border-0 me-2"}
                    type={"button"}
                    onClick={() => {
                        window.location.replace(localStorage.getItem('pathBack'))
                    }}>
                    {`${lang.body.general.btnBack}`}
                </button>
                <button
                    className={"col-6 col-md-4 btn btn-success border-0"}
                    type={"button"}
                    onClick={clickHandler}>
                    {lang.body.createLinkPage.btnCreateLink}
                </button>
            </div>
        </>

    )
}
