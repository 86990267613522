import React from "react"
import {Link} from "react-router-dom"
import {
    contextMenuItem,
    contextMenuCreateItem,
    getItems,
    menuCreateItem,
    getIcon,
    justifyContents,
    getLinkSmall,
    contextMenuOff, objectLang, filterHandler, removeSearchParameter, showAll, removeCookieOfFolderId,
} from "../functions"
import folderIcon from "../images/full-folder.png"
import folderIconEmpty from "../images/empty-folder.png"
import folderPublicIcon from "../images/public-full-folder.png"
import folderPublicEmptyIcon from "../images/public-empty-folder.png"
import flagCheckEmpty from "../images/check-empty.png"
import linkImg from "../images/link-logo-default.png"
import noteImg from "../images/note.png"
import img_context_menu from "../images/context-menu.png"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import {MenuItem} from "../elements/MenuItem"
import {FolderPage} from "../pages/FolderPage"
import {PathLineClass} from "../elements/PathLineClass"
import {MenuCreateItemPage} from "../elements/MenuCreateItemPage"
import {createBrowserHistory} from "history"
import config from "../config.json"

export class LinksClass extends React.Component {

    render() {

        const documents = this.props.documents
        const rootFolder = this.props.rootFolder
        let imgLink = linkImg
        if (!documents.opening) {
            documents.opening = 'open'
        }

        let folderImgFull = folderIcon
        let folderImgEmpty = folderIconEmpty
        if (documents.opening === 'open-public') {
            folderImgFull = folderPublicIcon
            folderImgEmpty = folderPublicEmptyIcon
        }
        const urlParams = new URLSearchParams(window.location.search)
        let textFilter = urlParams.get('filter')

        let items
        if (!documents) {
            return (
                <FolderPage/>
            )
        }

        if (documents.folders || documents.links || documents.notes) {
            items = getItems(documents)
        }

        if (documents.parent) {
            if (documents.opening === 'open-public' && documents.parent === rootFolder) {
                localStorage.setItem('pathBack', `/folder/open-public/${config.trialRootFolderId}`)
            } else if (documents.opening === 'open-public' && documents.parent !== rootFolder) {
                localStorage.setItem('pathBack', `/folder/open-public/${documents.parent}`)
            } else {
                localStorage.setItem('pathBack', `/folder/open/${documents.parent}`)
            }
        }

        const lang = objectLang().language

        if (textFilter && documents) {
            if (textFilter === '*') {
                textFilter = ''
            }
            setTimeout(() => {
                filterHandler(textFilter, documents)
            }, 200)
        }

        return (
            <>
                {<PathLineClass documents={documents}/>}
                <Container onClick={contextMenuOff}
                           id={"main-container"}
                           className={"m-0-auto p-4 pt-0 min-vh-100"}>
                    <div className={"title-body"}>
                        {/* Название папки */}
                        <Row id={"row-title"} xs={1} className={"d-flex justify-content-center m-0"}>
                            <div id={"title"} className={"p-0 mt-1 text-center"}>
                                <div id={'title-page'}
                                     className={"border-primary rounded mt-2 mb-3 d-flex justify-content-center align-items-baseline"}>

                                    <div className={'btn btn-light me-3'}>
                                        <div className={'text-decoration-none text-secondary fs-5 ps-2 pe-2'}>
                                            {/* "обновить текущую папку" */}
                                            <i className={`fa-solid fa-arrows-rotate`}
                                               onClick={() => {
                                                   removeCookieOfFolderId(documents.parent)
                                                   window.location.reload()
                                               }}/>
                                        </div>
                                    </div>
                                    <h2 id={"title-text"}
                                        className={"text-success mb-0 text-decoration-none"}>
                                        {(documents.name === 'Root-public' || documents.name === 'root-public') && lang.body.myLinksPage.titlePublicLinks}
                                        {(!documents.name || documents.name === 'Root' || documents.name === 'root') && lang.body.myLinksPage.title}
                                        {(documents.name !== 'Root' && documents.name !== 'root' && documents.name !== 'Root-public' && documents.name !== 'root-public') && documents.name}
                                    </h2>
                                    <div className={'btn btn-light ms-3'}>
                                        <div className={'text-decoration-none text-secondary fs-5 ps-2 pe-2'}>
                                            {/* "Создать элемент" */}
                                            <i className={`fa-solid fa-plus`}
                                               onClick={menuCreateItem}/>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={"border-primary rounded mt-2 mb-3 me-2 d-flex justify-content-center align-items-baseline"}>
                                    <input id={"filterInput"}
                                           className={"form-control me-1 ms-1 mb-2"}
                                           type={"search"}
                                           placeholder={lang.body.foldersPage.filterName}
                                           aria-label={"Filter"}
                                           defaultValue={textFilter}
                                           onChange={(e) => {
                                               removeSearchParameter()
                                               showAll()
                                               filterHandler(e.target.value, documents)
                                           }}
                                    />
                                </div>

                            </div>
                        </Row>
                    </div>

                    <div id={'body-items'} className={`body-items d-flex ${justifyContents(items).body_items_justify}`}
                         onContextMenu={contextMenuCreateItem}>
                        <div id={"row-items"} className={`d-flex ${justifyContents(items).row_items_justify}`}
                             style={{width: `${justifyContents(items).scrollWidth}px`}}>
                            <Row id={'items'} xs={2} md={4} lg={6}
                                 className={`d-flex ${justifyContents(items).row_justify} m-0-auto w-100`}>
                                {items && items.map((item, i) => {
                                    const key = `item-${i}`
                                    if (item.type === 'folder') {
                                        const folder = item
                                        const menuItemData = {
                                            item: 'folder',
                                            key: key,
                                            id: folder["_id"],
                                            parent: folder.parentFolder,
                                            from: 'view'
                                        }
                                        let icon = folderImgFull
                                        if (!folder.folders.length && !folder.links.length && !folder.notes.length) {
                                            icon = folderImgEmpty
                                        }
                                        return (
                                            <div key={key} className={"folder mx-1"}>
                                                <div className={"d-flex justify-content-center position-relative"}>
                                                    <Link to={`/folder/${documents.opening}/${folder["_id"]}`}>
                                                        <div className={'hand-click'}
                                                             onContextMenu={(e) => {
                                                                 contextMenuItem(e, key)
                                                             }}
                                                             onClick={() => {
                                                                 localStorage.setItem('pathBack', createBrowserHistory().location.pathname)
                                                             }}>
                                                            <img id={"img-folder"} src={icon}
                                                                 className={"w-100 img-folder  rounded-4"}
                                                                 alt={"..."}/>
                                                        </div>
                                                    </Link>
                                                    <div className={'hand-click'}
                                                         onClick={(e) => {
                                                             contextMenuItem(e, key)
                                                         }}>
                                                        <img id={"img-context-menu"} src={img_context_menu}
                                                             className={"w-100 rounded-2"}
                                                             alt={"..."}/>
                                                    </div>
                                                    {<MenuItem data={menuItemData}/>}
                                                </div>
                                                <p id={"name-folder"}
                                                   className={"name-folder text-center mb-2 text-black p-0 fw-bold"}>{folder.name}
                                                </p>
                                            </div>
                                        )
                                    } else if (item.type === 'link') {
                                        const link = item
                                        let flag = false, linkSmall = '', div_name_link = ''
                                        imgLink = link["thumb_url"]
                                        if (!imgLink) {
                                            imgLink = getIcon(link.from, linkImg)
                                        }
                                        if (imgLink.includes("link-logo-default")) {
                                            linkSmall = getLinkSmall(link.from)
                                            flag = true
                                            div_name_link = 'div-name-link'
                                        }
                                        link.parentFolder = item.parentFolder

                                        let checkIcon = flagCheckEmpty
                                        // setCheckIconLink(`img-check-${key}`, link.from, flagCheckTrue, flagCheckFalse, flagCheckEmpty).then()

                                        const menuItemData = {
                                            item: 'link',
                                            key: key,
                                            id: link["_id"],
                                            link: link.from,
                                            parent: link.parentFolder,
                                            from: 'view'
                                        }

                                        return (
                                            <div key={key} className={"link mx-1"}>
                                                <div className={"d-flex justify-content-center position-relative"}>
                                                    <Link to={link.from}
                                                          className={'link-link'}
                                                          onContextMenu={(e) => {
                                                              contextMenuItem(e, key)
                                                          }}
                                                          target={"_blank"}
                                                          rel={"noreferrer noopener"}>
                                                        <div className={'hand-click'}
                                                             onContextMenu={(e) => {
                                                                 contextMenuItem(e, key)
                                                             }}
                                                             onClick={() => {
                                                                 localStorage.setItem('pathBack', createBrowserHistory().location.pathname)
                                                             }}>
                                                            <img id={"img-link"} src={imgLink}
                                                                 className={"w-100 img-link rounded-4"}
                                                                 alt={"LINK"}/>
                                                        </div>
                                                    </Link>
                                                    <div className={'check-icon'}>
                                                        <img id={`img-check-${key}`} src={checkIcon}
                                                             className={"img-check w-100 rounded-2"}
                                                             alt={"..."}/>
                                                    </div>
                                                    <div className={'hand-click'}
                                                         onClick={(e) => {
                                                             contextMenuItem(e, key)
                                                         }}>
                                                        <img id={"img-context-menu"} src={img_context_menu}
                                                             className={"w-100 rounded-2"}
                                                             alt={"..."}/>
                                                    </div>
                                                    {<MenuItem data={menuItemData}/>}
                                                </div>
                                                {flag && <div className={'pe-2'}>
                                                    <a href={`${link.from}`}
                                                       className={'text-decoration-none'}
                                                       target={"_blank"}
                                                       rel={"noreferrer noopener"}
                                                       onContextMenu={(e) => {
                                                           contextMenuItem(e, key)
                                                       }}>
                                                        <p id={'p-link-from'}
                                                           className={'text-black text-link me-2 overflow-hidden'}>
                                                            <span id={'link-from'}>{linkSmall}</span>
                                                        </p>
                                                    </a>
                                                </div>}
                                                <div className={div_name_link}>
                                                    <p id={"name-link"}
                                                       className={"name-link text-center mb-2 text-black p-0 fw-bold"}>{link.name}
                                                    </p>
                                                </div>
                                            </div>
                                        )

                                    } else if (item.type === 'note') {
                                        const note = item
                                        const menuItemData = {
                                            item: 'note',
                                            key: key,
                                            id: note["_id"],
                                            parent: note.parentFolder,
                                            from: 'view'
                                        }

                                        return (
                                            <div key={key} className={"note mx-1"}>
                                                <div className={"d-flex justify-content-center position-relative"}>
                                                    <Link to={`/note/open/${note["_id"]}`}
                                                          onContextMenu={(e) => {
                                                              contextMenuItem(e, key)
                                                          }}>
                                                        <img id={"img-note"} src={noteImg}
                                                             className={"w-100 img-note rounded-4"}
                                                             alt={"..."}/>
                                                    </Link>
                                                    <div className={'hand-click'}
                                                         onClick={(e) => {
                                                             contextMenuItem(e, key)
                                                         }}>
                                                        <img id={"img-context-menu"} src={img_context_menu}
                                                             className={"w-100 rounded-2"}
                                                             alt={"..."}/>
                                                    </div>
                                                    {<MenuItem data={menuItemData}/>}
                                                </div>
                                                <p id={"name-note"}
                                                   className={"name-note text-center mb-2 text-black p-0 fw-bold"}>{note.name}
                                                </p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={`item-00`} className={"folder mx-1"}>
                                            </div>
                                        )
                                    }
                                })}
                            </Row>
                        </div>
                    </div>
                </Container>
                {<MenuCreateItemPage parent={documents.parent}/>}
            </>
        )
    }
}