import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {checkNumber, objectLang, setErrorWindow} from "../functions"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {YearDetailClass} from "../components/YearDetailClass"
import {createBrowserHistory} from "history"

export const YearDetailPage = () => {
    localStorage.setItem('page', 'YearDetailPage')

    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [moneyYear, setMoneyYear] = useState({})
    const [currency, setCurrency] = useState('')
    const [respYear, setRespYear] = useState('')
    const lang = objectLang().language
    const urlParams = new URLSearchParams(window.location.search)
    const year = urlParams.get('year')

    let host
    window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku

    const fetch = useCallback(async () => {

        try {
            const data = await request(
                `${host}/api/money/year/detail?year=${year}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                setMoneyYear(data['moneyYear'])
                setCurrency(data['currency'])
                setRespYear(data['year'])
            } else {
                setAllowed(true)
                setDataModal(setErrorWindow('', data.code))
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setErrorWindow('', '590', '', '', 'replaceTo /money', '', 'replaceTo /money'))
        }
    }, [auth, request, year, host])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const sendExpenseToYearHandler = async (dataObject) => {
        if (!checkNumber(dataObject.value)) {
            setDataModal(setErrorWindow('', 592, '', lang.body.general.btnBack, `replaceTo ${localStorage.getItem('pathYearDetail')}`))
            window.location.replace('/modal')
            return
        }
        if (dataObject.isMonthPay) {
            const arr = []
            for (const i in dataObject.isMonthPay) {
                const value = dataObject.isMonthPay[i]
                if (!value) {
                    arr.push(i)
                }
            }
            if (arr.length >= 12) {
                setDataModal(setErrorWindow('', 592, '', lang.body.general.btnBack, `replaceTo ${localStorage.getItem('pathYearDetail')}`))
                window.location.replace('/modal')
                return
            }
        }
        if (!dataObject.year ||
            !dataObject.nameField ||
            !dataObject.value ||
            dataObject.nameField.length < 3) {

            setDataModal(setErrorWindow('', 592, '', lang.body.general.btnBack, `replaceTo ${localStorage.getItem('pathYearDetail')}`))
            window.location.replace('/modal')
            return
        }
        try {
            const data = await request(
                `${host}/api/money/year/send-expense`,
                'POST',
                dataObject,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                window.location.reload()
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setErrorWindow('', '', e.message))
        }
    }

    const addExpense = (year) => {
        let result = {
            year: year
        }

        const currentMonth = new Date(Date.now()).getMonth()

        document.getElementById('btn-add-expense').className = 'd-none'
        const addExpenseName = document.getElementById('year-expense')
        addExpenseName.className = 'd-none'
        const child = document.createElement('div')
        child.className = 'row row-cols m-1 d-flex justify-content-center'
        child.id = 'child'

        const inputNameField = document.createElement('input')
        inputNameField.className = 'inputs-expense-year form-control'
        inputNameField.style.width = '70%'
        inputNameField.style.padding = '0 0.375rem 0 0.375rem'
        inputNameField.placeholder = 'Название платежа'

        const inputValue = document.createElement('input')
        inputValue.className = 'inputs-expense-year form-control'
        inputValue.style.width = '26%'
        inputValue.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        inputValue.placeholder = 'Платеж в месяц'

        const divButtons = document.createElement('div')
        divButtons.className = 'd-flex justify-content-center'

        const buttonSend = document.createElement('button')
        buttonSend.className = 'btn btn-warning btn-sm col col-sm-3 p-1 mt-2 ms-1'
        buttonSend.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        buttonSend.innerText = 'Отправить'

        const buttonCancel = document.createElement('button')
        buttonCancel.id = `btn-cancel-add-expense`
        buttonCancel.className = 'btn btn-info btn-sm col col-sm-3 p-1 mt-2'
        buttonCancel.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        buttonCancel.innerText = 'Отменить'

        const buttonChecked = document.createElement('input')
        buttonChecked.id = `btn-checked-all-months`
        buttonChecked.type = `checkbox`
        buttonChecked.autocomplete = `off`
        buttonChecked.className = 'btn-check'
        buttonChecked.checked = false

        const checkboxLabel = document.createElement('label')
        checkboxLabel.className = 'btn btn-outline-primary me-1 mt-2'
        checkboxLabel.htmlFor = 'btn-checked-all-months'
        checkboxLabel.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        checkboxLabel.innerText = 'Отметить все'

        divButtons.append(buttonChecked)
        divButtons.append(checkboxLabel)
        divButtons.append(buttonCancel)
        divButtons.append(buttonSend)

        child.appendChild(inputNameField)
        child.appendChild(inputValue)
        addExpenseName.after(child)

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ]
        const divMoths = document.createElement('div')
        divMoths.className = 'row mt-2 d-flex justify-content-center'
        const divJan = document.createElement('div')
        const divFeb = document.createElement('div')
        const divMar = document.createElement('div')
        const divApr = document.createElement('div')
        const divMay = document.createElement('div')
        const divJun = document.createElement('div')
        const divJul = document.createElement('div')
        const divAug = document.createElement('div')
        const divSep = document.createElement('div')
        const divOkt = document.createElement('div')
        const divNov = document.createElement('div')
        const divDec = document.createElement('div')

        const divs = [
            divJan, divFeb, divMar, divApr, divMay, divJun, divJul, divAug, divSep, divOkt, divNov, divDec
        ]

        for (const i in months) {
            divMoths.append(divs[i])
            divs[i].className = 'form-check form-check-inline col col-sm-6 col-xl-3'
            const input = document.createElement('input')
            input.className = 'form-check-input'
            input.id = `checkbox-${i}`
            input.type = 'checkbox'
            const label = document.createElement('label')
            label.className = 'form-check-label'
            label.for = `checkbox-${i}`
            label.innerText = months[i]
            if (i === String(currentMonth)) {
            //     input.defaultChecked = true
            }
            divs[i].append(input)
            divs[i].append(label)
        }

        for (const i in divs) {
            divMoths.append(divs[i])
        }
        child.append(divMoths)
        child.appendChild(divButtons)

        buttonSend.onclick = () => {
            localStorage.setItem('pathYearDetail', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            result.nameField = inputNameField.value
            result.value = inputValue.value
            let isMonthPay = {}
            for (const i in months) {
                isMonthPay[months[i]] = divMoths.childNodes[i].childNodes.item(0)['checked']
            }
            result.isMonthPay = isMonthPay
            sendExpenseToYearHandler(result).then()
        }

        buttonCancel.onclick = () => {
            window.location.reload()
        }

        buttonChecked.onclick = () => {
            for (const i in months) {
                const input = document.getElementById(`checkbox-${i}`)
                if (input.checked) {
                    input.checked = false
                    buttonChecked.checked = false
                } else {
                    input.checked = true
                    buttonChecked.checked = true
                }
            }
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    const dataToClass = {
        currency: currency,
        year: respYear,
        moneyYear: moneyYear,
    }

    return (
        <>
            <YearDetailClass
                lang={lang}
                data={dataToClass}
                addExpense={addExpense}
            />
        </>
    )
}
