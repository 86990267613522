import React, {useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {deleteCookies} from "../functions"

export const ModalWin = ({data}) => {
    if (!data.btnOKColor) {
        data.btnOKColor = 'danger'
    }
    if (!data.btnCancelColor) {
        data.btnCancelColor = 'secondary'
    }
    const [show, setShow] = useState(true)
    let classCancel = ""
    if (data.btnCancel === "off") {
        classCancel = "d-none"
    }

    const clickHandler = (str) => {
        if (!str) {
            return window.location.reload()
        }

        const arrStrBackspace = str.split(' ')
        setShow(false)

        if (str === 'reload') {
            return window.location.reload()

        } else if (str === 'deleteCookies') {
            deleteCookies()
            return window.location.reload()

        } else if (arrStrBackspace[0] === 'replaceToAuth') {
            return window.location.replace('/auth')

        } else if (arrStrBackspace[0] === 'replaceToAuthAndDeleteCookies') {
            deleteCookies()
            return window.location.replace('/auth')

        } else if (str === 'replaceToMain') {
            return window.location.replace('/main')

        } else if (str === 'replaceToMainAndDeleteCookies') {
            deleteCookies()
            return window.location.replace('/main')

        } else if (str === 'replaceToAuthAndDeleteCookies') {
            deleteCookies()
            return window.location.replace('/auth')

        } else if (str === 'replaceToBack') {
            const pathBack = localStorage.getItem('pathBack')
            return window.location.replace(pathBack)

        } else if (arrStrBackspace[0] === 'replaceTo') {
            return window.location.replace(arrStrBackspace[1])

        } else {
            return window.location.reload()
        }
    }

    return (
        <>
            <Modal show={show}>
                <Modal.Header className={'d-flex justify-content-between'}>
                    <Modal.Title>{data.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{data.desc}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={data.btnOKColor}
                        onClick={() => {
                            clickHandler(data.clickOK)
                        }}>
                        {data.btnOK}
                    </Button>
                    <Button
                        variant={data.btnCancelColor}
                        className={classCancel}
                        onClick={() => {
                            clickHandler(data.clickCancel)
                        }}>
                        {data.btnCancel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}