import React, {useContext, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {ModalCreateClass} from "../elements/ModalCreateClass"
import config from "../config.json"

export const CreateMoneyPage = () => {
    localStorage.setItem('page', 'CreateMoneyPage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [show, setShow] = useState(true)

    const currentYear = new Date(Date.now()).getFullYear()

    const data = {
        title: lang.body.moneyPage.createNewYear,
        desc: `${lang.body.moneyPage.currentYear}: ${currentYear}`,
        btnOKColor: 'success',
        btnCancelColor: 'secondary',
        btnOK: lang.body.moneyPage.btnCreate,
        btnCancel: lang.body.moneyPage.btnCancel
    }

    const closeHandler = () => {
        setShow(false)
    }

    const createNewYearHandler = async () => {
        const year = document.getElementById('create-year').value
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/create?year=${year}`,
                'POST',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 201) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.replace("/money/main")
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    const cancelHandler = async () => {
        window.location.replace(localStorage.getItem('pathBack'))
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            <ModalCreateClass
                show={show}
                data={data}
                currentYear={currentYear}
                createNewYearHandler={createNewYearHandler}
                cancelHandler={cancelHandler}
                closeHandler={closeHandler}
            />
        </>
    )
}