import React from 'react'

export class MainMoneyClass extends React.Component {

    render() {

        const {dataYears, currency} = {
            dataYears: this.props.dataYears,
            currency: this.props.currency,
        }

        const years = []
        for (const yearName in dataYears) {
            const year = dataYears[yearName]
            years.push(year)
        }

        return (
            <>
                <div className={"container mt-3"}>
                    <div className={"row"}>

                        {years.map((year, index) => {
                            const m = {
                                yearName: year['yearName'],
                                income: year['incomesTotal'],
                                expense: year['expensesTotal'],
                                balance: year['balance']
                            }
                            return (
                                <div className={"col-sm-4"} key={index}>
                                    <a href={`/money?year=${m.yearName}`} className={"text-decoration-none"}>
                                        <div className={"card mb-2"}>
                                            <div className={"card-body"}>
                                                <h5 className={"card-title border-bottom mb-3 pb-1"}>{m.yearName}</h5>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Доходы всего:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.income}</strong>
                                                        <strong
                                                            className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Расходы всего:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-danger"}>{m.expense}</strong>
                                                        <strong
                                                            className={"m-0 text-danger"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Баланс:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.balance}</strong>
                                                        <strong
                                                            className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                        <div className={"col-sm-4"}>
                            <a href={`/money/year/create`} className={"text-decoration-none"}>
                                <div className={"card mb-2"}>
                                    <div className={"card-body"}>
                                        <div className={"d-flex m-0"}>
                                            <div className={"p-1 w-100"}>
                                                <i className={"d-flex text-success justify-content-center fa-solid fa-plus fs-1"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
