import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {YearMoneyClass} from "../components/YearMoneyClass"
import {createBrowserHistory} from "history"

export const YearMoneyPage = () => {
    localStorage.setItem('page', 'YearMoneyPage')
    const lang = objectLang().language
    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [money, setMoney] = useState({})
    const [dataYear, setDataYear] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataModalCatch, setDataModalCatch] = useState({})
    const currentLang = lang.lang
    const urlParams = new URLSearchParams(window.location.search)
    const year = urlParams.get('year')

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money?lang=${currentLang}&year=${year}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200 && data.status !== 404) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code, data.message, '', 'replaceTo /main'))
            }

            if (data.status === 404) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    '',
                    data.code, data.message,
                    '',
                    'replaceTo /money/year/create',
                    '',
                    '',
                    'success',
                    ''
                ))
            }

            if (data.status === 200) {
                setMoney(data['money'])
                setDataYear(data['dataYear'])
                localStorage.setItem('pathBack', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [request, auth, currentLang, year])

    useEffect(() => {
        getData().then()
    }, [getData])

    let objectMoney = {}, years = {}, months = {}
    for (const year in money['years']) {
        const yearMoney = money['years'][year]
        for (const month in yearMoney) {
            if (month !== 'incomeYear' && month !== 'expenseYear' && month !== 'balanceYear') {
                months[month] = yearMoney[month]
            } else {
                years[year] = {[month]:yearMoney[month]}
            }
        }
        objectMoney[year] = {year: years[year], months: months}
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    return (
        <>
            <YearMoneyClass
                lang={lang}
                dataYear={dataYear}
            />
        </>
    )
}