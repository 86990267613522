import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import {NotePage} from "../pages/NotePage"

export const ViewWindowQuestion = ({newNote, data}) => {

    const [show, setShow] = useState(true)

    const closeHandler = () => {
        setShow(false)
        return (<NotePage noteObject={newNote} />)
    }

    const clickHandler = (str) => {
        if (!str) {
            closeHandler()
        } else if (str === 'replaceToBack') {
            const pathBack = localStorage.getItem('pathBack')
            return window.location.replace(pathBack)
        } else if (str === 'saveNote') {
            return window.location.replace(`/note/save/${newNote._id}`)
        } else if (str === 'saveNewNote') {
            return window.location.replace(`/note/save/new`)
        } else if (str === 'tapeNoteName') {
            setShow(false)
            return (<NotePage noteObject={newNote} />)
        } else {
            return window.location.reload()
        }
    }

    let classCancel = ""
    if (data.btnCancel === "off") {
        classCancel = "d-none"
    }

    return (
        <>
            <Modal id={"quest-window"} show={show}>
                <Modal.Header>
                    <Modal.Title className={'w-100'}>{data.title}</Modal.Title>
                    <Button className={'ms-2'}
                        variant={"close"}
                        onClick={closeHandler}>
                    </Button>
                </Modal.Header>
                <Modal.Body>{data.desc}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={data.btnOKColor}
                        onClick={() => {
                            clickHandler(data.clickOK)
                        }}>
                        {data.btnOK}
                    </Button>
                    <Button
                        variant={data.btnCancelColor}
                        className={classCancel}
                        onClick={() => {
                            clickHandler(data.clickCancel)
                        }}>
                        {data.btnCancel}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}