import React, {useContext, useState} from "react"
import {AuthContext} from "../context/AuthContext"
import {getAvatarImagePath, getImagePath, objectLang, removeCookieOfFolderId, setErrorWindow} from "../functions"
import config from "../config.json"
import {UploadSingleImageClass} from "../components/UploadSingleImageClass"

export const UploadSingleImageFilePage = ({isAvatar = false}) => {
    localStorage.setItem('page', 'UploadSingleImageFilePage')

    const auth = useContext(AuthContext)

    const documents = JSON.parse(localStorage.getItem('documents'))

    let imagePathName = ''
    if (isAvatar) {
        imagePathName = getAvatarImagePath(auth)
    } else {
        imagePathName = getImagePath(documents, auth)
    }

    const lang = objectLang().language

    const [disabledButton, setDisabledButton] = useState(true)
    const [progressBarOpacity, setProgressBarOpacity] = useState(0)
    const [nameImageFile, setNameImageFile] = useState('0')

    const allowedTypes = 'image/jpeg, image/png, image/ico, image/gif, image/tiff, image/svg, image/psd'

    const assertFilesValid = (file) => {
        const fileName = file.name
        const fileSize = file.size
        const sizeLimit = 10485760 // 10 megabyte
        if (!allowedTypes.includes(file.type)) {
            setDisabledButton(true)
            throw new Error(`❌ File "${fileName}" Downloading this file is not supported`)
        } else {
            document.querySelector('#statusMessage').innerHTML = ` ✅ File "${fileName}" is ready to send!`
            setDisabledButton(false)
        }
        if (fileSize > sizeLimit) {
            setDisabledButton(true)
            throw new Error(`❌ File "${fileName}" The uploaded file size cannot be more than 10 MB.`)
        } else {
            document.querySelector('#statusMessage').innerHTML = ` ✅ File "${fileName}" is ready to send!`
            setDisabledButton(false)
        }
    }

    const showPendingState = () => {
        setDisabledButton(true)
        document.querySelector('#statusMessage').innerHTML = ' ⏳ Pending...'
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        showPendingState()
        uploadFiles()
    }

    const updateProgressBar = (value) => {
        const percent = value * 100
        document.querySelector('progress').value = Math.round(percent)
    }

    const changeNameHandler = (e) => {
        const name = e.target.value
        setNameImageFile(name)
    }

    const handleInputChange = (e) => {

        setProgressBarOpacity(100)

        document.querySelector('#statusMessage').innerHTML = " 🤷‍♂ Nothing's uploaded"

        try {
            let input = e.target

            assertFilesValid(input.files[0])

            let nameFile = '', labelVal

            if (input.files[0])
                nameFile = input.files[0].name

            let label = document.querySelector('.input__file-button')
            labelVal = label.innerText

            if (nameFile) {
                label.querySelector('.input__file-button-text').innerText = `${lang.body.uploadFilePage.inputTextOne} ` + nameFile
                changeNameHandler({target: {value: nameFile}})
                document.querySelector('#file-name').value = nameFile
            } else {
                label.querySelector('.input__file-button-text').innerText = labelVal
            }

        } catch (err) {
            document.querySelector('#statusMessage').innerHTML = err.message
            return
        }

        setDisabledButton(false)
    }

    const uploadFiles = () => {

        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku

        const handleSubmit = (event) => {
            event.preventDefault()
        }

        const form = document.querySelector('#form_file')
        form.addEventListener('submit', handleSubmit)

        const url = `${host}/api/cloudinary/single-image-upload`
        const method = 'post'
        const xhr = new XMLHttpRequest()
        const data = new FormData(form)

        xhr.open(method, url)

        xhr.addEventListener('loadend', () => {
            const pathBack = localStorage.getItem('pathBack')
            if (xhr.status === 200) {
                document.querySelector('#statusMessage').innerHTML = ' ✅ Success'
                const pathBackArr = pathBack.split('/')
                const folderParent = localStorage.getItem('pathBack').split('/')[pathBackArr.length - 1]
                removeCookieOfFolderId(folderParent)
                window.location.replace(localStorage.getItem('pathBack'))
            } else {
                document.querySelector('#statusMessage').innerHTML = ' ❌ Error'
                localStorage.setItem('dataModal', JSON.stringify(
                    setErrorWindow('',
                        '',
                        '',
                        '',
                        `replaceTo ${pathBack}`
                    )
                ))
                window.location.replace('/modal')
            }
        })

        xhr.upload.addEventListener('progress', event => {
            document.querySelector('#statusMessage').innerHTML = ` ⏳ Uploaded ${event.loaded} bytes of ${event.total}`
            updateProgressBar(event.loaded / event.total)
        })

        xhr.addEventListener('loadend', () => {
            updateProgressBar(100)
        })

        xhr.setRequestHeader("date-created", Date.now().toString())
        xhr.setRequestHeader("name-image-file", encodeURIComponent(nameImageFile))
        xhr.setRequestHeader("image-path-name", imagePathName)
        xhr.setRequestHeader("Authorization", `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`)

        xhr.send(data)
    }

    return (
        <>
            <UploadSingleImageClass
                documents={documents}
                changeNameHandler={changeNameHandler}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                disabledButton={disabledButton}
                progressBarOpacity={progressBarOpacity}
                allowedTypes={allowedTypes}
            />
        </>
    )
}