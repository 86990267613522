import {useState, useCallback, useContext} from 'react'
import {refreshAT} from "../functions"
import {AuthContext} from "../context/AuthContext"

export const useHttp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const auth = useContext(AuthContext)

    const request = useCallback(async (url, method = 'GET', body = null, headers = {}, load = true) => {
            setLoading(load)
            try {
                if (body) {
                    body = JSON.stringify(body)
                    headers['Content-Type'] = 'application/json'
                }

                const response = await fetch(url, {method, body, headers})
                const data = await response.json()

                if (!response.ok) {
                    setLoading(true)
                    if (data.code === 6041) {
                        setLoading(false)
                        await refreshAT(auth, request).then(() => {
                            return data
                        })
                    }
                    if (response.status === 500) {
                        setError(data.message)
                        localStorage.removeItem('userData')
                        window.location.reload()
                    }
                }

                setLoading(false)
                return data

            } catch
                (e) {
                setLoading(false)
                setError(e.message)
                throw e
            }
        }, [auth]
    )

    const clearError = useCallback(() => setError(null), [])

    return {loading, request, error, clearError}
}
