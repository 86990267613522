import React from 'react'

export class YearDetailClass extends React.Component {

    render() {

        const {data, addExpense} = {
            data: this.props.data,
            addExpense: this.props.addExpense,
        }

        const currency = data.currency
        const incomes = data.moneyYear['incomesOfNamesTotals']
        const expenses = data.moneyYear['expensesOfNamesTotals']
        const arrIncomes = [], arrIncomesCategories = [], arrExpenses = [], arrExpensesCategories = []

        if (incomes) {
            for (const inc in incomes) {
                const value = incomes[inc]
                arrIncomes.push([inc, value])
            }
        }
        const totalIncomeValue = data.moneyYear['incomesYearTotal']
        const totalExpensesValue = data.moneyYear['expensesYearTotal']
        const yearBalance = data.moneyYear['balance']

        if (expenses) {
            for (const exp in expenses) {
                const value = expenses[exp]
                arrExpenses.push([exp, value])
            }
        }

        for (const i in arrIncomes) {
            const category = arrIncomes[i]
            const nameCategory = category[0]
            const valueCategory = category[1]
            arrIncomesCategories.push([nameCategory, valueCategory])
        }

        for (const i in arrExpenses) {
            const category = arrExpenses[i]
            const nameCategory = category[0]
            const valueCategory = category[1]
            arrExpensesCategories.push([nameCategory, valueCategory])
        }

        return (
            <>
                <div className={"container mt-3 col col-xl-9"}>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={'col col-12 d-flex justify-content-between mb-3'}>
                                <div className={'col col-6 d-flex justify-content-end'}>
                                    <h4 className={"card-title m-auto"}>{`${data.year}`}</h4>
                                </div>
                                <div className={'col col-5 d-flex justify-content-end'}>
                                    <h5 className={"card-title m-auto pe-2"}>{`Баланс:`}&nbsp;{yearBalance}</h5>
                                </div>
                                <button id={"button-close-year"}
                                        type={"button"}
                                        className={"col col-1 btn-close fs-3 m-0"}
                                        aria-label={"Close"}
                                        onClick={() => {
                                            window.location.replace(localStorage.getItem('pathBack'))
                                        }}>
                                </button>
                            </div>

                            <div className={`year-income-expense m-1`}>

                                {/* Годовые Доходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div className={'col align-self-end'}>
                                        <h4 className={"card-title text-center m-1 mb-3 pb-1"}>{`Доходы:`}</h4>

                                        {arrIncomesCategories.map((category, index) => {
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div className={'row m-1'} key={index}>
                                                        <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 className={'m-0'}>{nameCat}</h6>
                                                            <p className={'m-0'}>{`${value} ${currency}`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}

                                    </div>

                                    <div className={'row m-1 mt-4'}>
                                        <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                            <h6 className={'m-0 text-success'}>{'Всего доходов в году'}</h6>
                                            <p className={'m-0'}>{`${totalIncomeValue} ${currency}`}</p>
                                        </div>
                                    </div>

                                </div>

                                {/* Годовые Расходы */}
                                <div className={'col border border-3 rounded-2 m-2 ps-2 pe-2 pt-3 pb-3'}>
                                    <div id={'add-expense ps-2 pe-2'}>
                                        <h4 id={'add-expense-name'}
                                            className={"card-title text-center m-1 mb-3 pb-1"}>{`Расходы:`}</h4>
                                        <div id={'year-expense'} className={`row row-cols m-1 mb-3`}>
                                            <button id={'btn-add-expense'}
                                                    className={'btn btn-warning btn-sm col col-sm-4 p-1'}
                                                    onClick={() => {
                                                        addExpense(data.year)
                                                    }}>
                                                {'Добавить строку'}
                                            </button>
                                        </div>

                                        {arrExpensesCategories.map((category, index) => {
                                            const nameCat = category[0]
                                            const value = category[1]
                                            if (nameCat !== 'total') {
                                                return (
                                                    <div className={'row m-1'} key={index}>
                                                        <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                            <h6 className={'m-0'}>{nameCat}</h6>
                                                            <p className={'m-0'}>{`${value} ${currency}`}</p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return (<></>)
                                            }
                                        })}

                                        <div className={'row m-1 mt-4'}>
                                            <div className={'col col-12 d-flex justify-content-between border-bottom'}>
                                                <h6 className={'m-0 text-danger'}>{'Всего расходов в году'}</h6>
                                                <p className={'m-0'}>{`${totalExpensesValue} ${currency}`}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
