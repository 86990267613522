import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {checkNumber, objectLang, setErrorWindow} from "../functions"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {MonthDetailClass} from "../components/MonthDetailClass"
import {createBrowserHistory} from "history"

export const MonthDetailPage = () => {
    localStorage.setItem('page', 'MonthDetailPage')

    const auth = useContext(AuthContext)
    const {loading, request} = useHttp()
    const [allowed, setAllowed] = useState(true)
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [dataMonth, setDataMonth] = useState({})
    const [dataModalCatch, setDataModalCatch] = useState({})
    const urlParams = new URLSearchParams(window.location.search)
    const month = urlParams.get('month')
    const year = urlParams.get('year')
    const lang = objectLang().language
    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/month/detail?year=${year}&month=${month}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                setDataMonth(data['dataMonth'])
            }

            setAllowed(true)
            setDataModal(setErrorWindow('', data.code))

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setErrorWindow('', '590', '', '', 'replaceTo /money', '', 'replaceTo /money'))
        }
    }, [auth, request, year, month])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const sending = async (dataObject) => {
        if (!checkNumber(dataObject.value)) {
            setDataModal(setErrorWindow('', 592, '', lang.body.general.btnBack, `replaceTo ${localStorage.getItem('pathMonthDetail')}`))
            window.location.replace('/modal')
            return
        }
        if (!dataObject.year ||
            !dataObject.month ||
            !dataObject.purpose ||
            !dataObject.nameField ||
            !dataObject.value ||
            dataObject.nameField.length < 3) {

            setAllowed(true)
            setDataModal(setErrorWindow('', 592, '', lang.body.general.btnBack, `replaceTo ${localStorage.getItem('pathMonthDetail')}`))
            window.location.replace('/modal')
            return
        }
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/money/month/adding`,
                'POST',
                dataObject,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
            }

            if (data.status === 200) {
                window.location.reload()
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setErrorWindow('', '', e.message))
        }
    }

    const createBlockSend = (to) => {
        let result = {
            year: year,
            month: month,
            purpose: to
        }
        document.getElementById(`btn-add-${to}`).className = 'd-none'
        const addExpenseName = document.getElementById(`month-${to}`)
        const child = document.createElement('div')
        child.className = 'row row-cols m-1 d-flex justify-content-center'
        child.id = 'child'

        const inputNameField = document.createElement('input')
        inputNameField.className = 'inputs-expense-year form-control'
        inputNameField.style.width = '69%'
        inputNameField.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        inputNameField.placeholder = 'Название'

        const inputValue = document.createElement('input')
        inputValue.className = 'inputs-expense-year form-control'
        inputValue.style.width = '26%'
        inputValue.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        inputValue.placeholder = 'значение'

        const divButtons = document.createElement('div')
        divButtons.className = 'd-flex justify-content-start'

        const buttonSend = document.createElement('button')
        buttonSend.id = `btn-send-${to}`
        buttonSend.className = 'btn btn-warning btn-sm col col-sm-3 p-1 mt-2 ms-1'
        buttonSend.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        buttonSend.innerText = 'Отправить'

        const buttonCancel = document.createElement('button')
        buttonCancel.id = `btn-close-${to}`
        buttonCancel.className = 'btn btn-info btn-sm col col-sm-3 p-1 mt-2'
        buttonCancel.style.padding = '0.2rem 0.375rem 0.2rem 0.375rem'
        buttonCancel.innerText = 'Отменить'

        divButtons.append(buttonCancel)
        divButtons.append(buttonSend)

        child.appendChild(inputNameField)
        child.appendChild(inputValue)
        addExpenseName.after(child)
        child.appendChild(divButtons)

        buttonCancel.onclick = () => {
            window.location.reload()
        }
        buttonSend.onclick = () => {
            localStorage.setItem('pathMonthDetail', createBrowserHistory().location.pathname + createBrowserHistory().location.search)
            result.nameField = inputNameField.value
            result.value = inputValue.value
            sending(result).then()
        }
    }

    const addIncomeHandler = () => {
        createBlockSend('income')
    }

    const addExpenseHandler = () => {
        createBlockSend('expense')
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return (
            <ModalWin data={dataModal}/>
        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    return (
        <>
            <MonthDetailClass
                data={dataMonth}
                addIncome={addIncomeHandler}
                addExpense={addExpenseHandler}
            />
        </>
    )
}
