import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {NavLink} from "react-router-dom"
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {createBrowserHistory} from "history"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {ModalWin} from "../elements/ModalWin"
import {PathLineClass} from "../elements/PathLineClass"
import {LoaderClass} from "../elements/LoaderClass"

export const MainPage = () => {
    localStorage.setItem('page', 'MainPage')
    localStorage.setItem('modal', JSON.stringify(setErrorWindow('', 540)))

    const history = createBrowserHistory()
    localStorage.setItem('pathBack', history.location.pathname)
    history.push('/')

    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [languages, setLanguages] = useState(objectLang().languages)
    const [linkMyLinks, setLinkMyLinks] = useState('/my-links')

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/check`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
                return
            }

            const arrLang = data.languages.language
            let language = {}
            for (const key in arrLang) {
                language[arrLang[key].lang] = arrLang[key]
            }
            setLanguages(language)
            if (data.user) {
                setLinkMyLinks(data.user.rootFolder)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, request, setLinkMyLinks])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const currentLang = objectLang().language.lang
    if (JSON.stringify(objectLang().languages) !== JSON.stringify(languages)) {
        localStorage.setItem('languages', JSON.stringify(languages))
        localStorage.setItem('language', JSON.stringify(languages[currentLang]))
    }

    const lang = objectLang().language
    const localStorageLang = lang.lang

    if (auth.accessToken) {
        // elementsNav = langNavbar.isAuth // Кнопочки на главной странице что приходят из базы данных
        if (!localStorage.getItem('isChangedLang')) {
            if (auth.language !== localStorageLang) {
                for (const i in languages) {
                    if (i === auth.language) {
                        localStorage.setItem('language', JSON.stringify(languages[i]))
                    }
                }
                localStorage.setItem('isChangedLang', 'true')
                window.location.reload()
            }
        }
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    const arrLinks = [
        {
            "name": lang.navbar.isNotAuth.btnAuth,
            "link": "/auth",
            "className": "Auth",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isNotAuth.btnRegister,
            "link": "/register",
            "className": "Register",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isAuth.btnMyLinks,
            "link": `/folder/open/${linkMyLinks}`,
            "className": "MyLinks",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isAuth.btnMoney,
            "link": `/money/main`,
            "className": "Money",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isAuth.btnPublicLinks,
            "link": "/folder/open-public/666a0310c1c139c28daa572b",
            "className": "PublicLinks",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isAuth.btnProfile,
            "link": "/profile",
            "className": "Profile",
            "bodyClass": ""
        },
        {
            "name": lang.navbar.isAuth.btnOut,
            "link": "/exit",
            "className": "Out",
            "bodyClass": "exit"
        }
    ]

    return (
        <>
            {<PathLineClass/>}
            <div className={"d-flex justify-content-center flex-wrap"}>

                {!auth.isAuthenticated && <NavLink
                    to={`${arrLinks[0].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[0].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[0].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 0}>{arrLinks[0].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {!auth.isAuthenticated && <NavLink
                    to={`${arrLinks[1].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[1].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[1].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 1}>{arrLinks[1].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {auth.isAuthenticated && <NavLink
                    to={`${arrLinks[2].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[2].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[2].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 2}>{arrLinks[2].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {auth.isAuthenticated && <NavLink
                    to={`${arrLinks[3].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[3].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[3].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 3}>{arrLinks[3].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {(currentLang === 'ru') && <NavLink
                    to={`${arrLinks[4].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[4].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[4].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 4}>{arrLinks[4].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {auth.isAuthenticated && <NavLink
                    to={`${arrLinks[5].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[5].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[5].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 5}>{arrLinks[5].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}

                {auth.isAuthenticated && <NavLink
                    to={`${arrLinks[6].link}`}
                    className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${arrLinks[6].className}`}>
                    <div className={"m-1 w-auto"}>
                        <div className={`card border-primary p-3 text-center ${arrLinks[6].bodyClass}`}>
                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                <h5 className={"card-title title-card"}><strong
                                    id={'main-page-button-' + 6}>{arrLinks[6].name}</strong>
                                </h5>
                            </div>
                        </div>
                    </div>
                </NavLink>}
            </div>
            {!auth.isAuthenticated && <div className={'container mt-4'}>
                <h5 className={'text-success text-start text-wrap ms-md-2 ps-3 pe-3 fs-5'}>
                    {lang.body.welcome}
                </h5>
            </div>}
        </>
    )
}
