import React, {useContext, useState} from 'react'
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import config from "../config.json"
import {useHttp} from "../hooks/http.hook"
import {ViewResultsSearchClass} from "../components/ViewResultsSearchClass"
import {AuthContext} from "../context/AuthContext"
import {setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "./ModalWin"

export const ViewWindowSearch = ({lang}) => {

    const [show, setShow] = useState(true)
    const {request} = useHttp()
    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [resultSearch, setResultSearch] = useState({
        title: lang.messages.code_701,
        requestSearch: '',
        body: {
            folders: [],
            links: [],
            notes: [],
        },
        btnBack: 'Назад',
        loading: false
    })

    let timerId

    const closeHandler = () => {
        setShow(false)
    }

    const searchHandler = (e) => {

        clearTimeout(timerId)

        timerId = setTimeout(async () => {

            if (e.target.value === '') {
                return
            } else {
                setResultSearch({
                    title: '',
                    requestSearch: e.target.value,
                    body: {
                        folders: [],
                        links: [],
                        notes: []
                    },
                    btnBack: 'Назад',
                    loading: true
                })
            }

            let host
            window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
            let data = {
                founded_folders: [],
                founded_links: [],
                founded_notes: [],
                searchingLang: '',
                searchingWord: ''
            }
            try {
                data = await request(
                    `${host}/api/search?query=${e.target.value}`,
                    'GET',
                    null,
                    {
                        Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                    })

                if (data.status > 200) {
                    setAllowed(false)
                    setDataModal(setErrorWindow('', data.code))
                }

                if (data.searchingLang !== 'original') {
                    document.getElementById("navSearch").value = data.searchingWord
                }
                setResultSearch({
                    title: lang.messages[`code_${data.code}`],
                    requestSearch: e.target.value,
                    body: {
                        folders: data.founded_folders,
                        links: data.founded_links,
                        notes: data.founded_notes
                    },
                    btnBack: 'Назад',
                    loading: false
                })
            } catch (e) {
                setAllowedCatch(false)
                setDataModalCatch(setDataModalFromCatch(e))
            }
        }, 1000)
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            <Modal id={"search-window"} show={show}>
                <Modal.Header>
                    <Modal.Title className={'w-100'}>
                        <input id={"navSearch"}
                               className={"form-control me-1 ms-1"}
                               type={"search"}
                               placeholder={lang.navbar.isAuth.placeholderSearch}
                               aria-label={"Search"}
                               onChange={searchHandler}
                        />
                    </Modal.Title>
                    <Button className={'ms-2'}
                            variant={"close"}
                            onClick={closeHandler}>
                    </Button>
                </Modal.Header>

                <ViewResultsSearchClass data={resultSearch}/>

                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}