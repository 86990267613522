import React from 'react'

export class YearMoneyClass extends React.Component {

    render() {

        const {lang, dataYear} = {
            lang: this.props.lang,
            dataYear: this.props.dataYear,
        }

        let yearName = 'year', currency = '$', balance = '0'
        const months = [], dataMonths = []

        for (const field in dataYear) {
            yearName = field // название года
            currency = dataYear[field].currency // валюта
            balance = dataYear[field].balance // баланс
            const month = dataYear[field].months
            for (const m in month) {
                months.push(m) // Массив имён месяцев
                dataMonths.push(month[m]) // Массив объектов для каждого месяца года
            }
        }

        return (
            <>
                <div className={"container mt-3"}>
                    <div className={"row"}>
                        <div className={"col-sm-12"}>
                            <div className={"card mb-2"}>
                                <a href={`/money/year/detail?year=${yearName}`} className={"text-decoration-none text-black"}>
                                    <div className={"card-body row col col-11"}>
                                        <div className={'col col-6 d-flex justify-content-end'}>
                                            <h5 className={"card-title m-auto"}>{yearName}</h5>
                                        </div>
                                        <div className={'col col-6 d-flex justify-content-start'}>
                                            <h5 className={"card-title m-auto"}>{`Баланс:`}&nbsp;{balance}</h5>
                                        </div>
                                    </div>
                                </a>
                                {/*<button className="carousel-control-prev text-dark" type="button">*/}
                                {/*    <i className={`fa-solid fa-chevron-left`}*/}
                                {/*       onClick={() => {*/}

                                {/*       }}/>*/}
                                {/*</button>*/}
                                {/*<button className="carousel-control-next text-dark" type="button">*/}
                                {/*    <i className={`fa-solid fa-chevron-right`}*/}
                                {/*       onClick={() => {*/}

                                {/*       }}/>*/}
                                {/*</button>*/}
                            </div>
                        </div>

                        {months.map((month, index) => {
                            const m = {
                                id: month,
                                name: lang.body.moneyPage[month],
                                income: dataMonths[index]['incomesMonthTotal'],
                                expense: dataMonths[index]['expensesMonthTotal'],
                                balance: dataMonths[index]['balanceMonth']
                            }
                            return (
                                <div className={"col-sm-4"} key={index}>
                                    <a href={`/money/month/detail?year=${yearName}&month=${m.id}`} className={"text-decoration-none"}>
                                        <div className={"card mb-2"}>
                                            <div className={"card-body"}>
                                                <h5 className={"card-title border-bottom mb-3 pb-1"}>{m.name}</h5>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Доходы всего:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.income}</strong>
                                                        <strong className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Расходы всего:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-danger"}>{m.expense}</strong>
                                                        <strong className={"m-0 text-danger"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                                <div className={"d-flex m-0"}>
                                                    <div className={"p-1 w-100"}>Баланс:</div>
                                                    <div className={"p-1 flex-shrink-1"}>
                                                        <strong className={"m-0 text-success"}>{m.balance}</strong>
                                                        <strong className={"m-0 text-success"}>&nbsp;{currency}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </>
        )
    }
}
